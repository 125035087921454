import {useLocation} from "react-router-dom";
import React from "react";

import locations from '../../data/locations.json'
import {LocationComponent} from "./locationComponent";

export function LodgeDetails() {

    const linkState = useLocation()
    const lodge = linkState.state

    const location = locations.find(x => x.location === lodge.location)

    return <>
        <section className="section section--gradient">
            <div className="container">
                <div className="content has-text-centered">
                    <h1 className="title">{lodge.name} No. {lodge.number}</h1>
                </div>

                <div className="content">
                    <h2 className="has-text-centered">
                        {lodge.location}
                    </h2>

                    <p className="has-text-centered">
                        Meets on the {lodge.meetingdetails}
                    </p>
                    <p className="has-text-centered">
                        Installation meeting {lodge.installation}
                    </p>
                </div>


                <LocationComponent location={location}/>

            </div>

        </section>
    </>
}