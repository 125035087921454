import {useEffect, useState} from "react";
import FourthStepContents from "../../markdown/mark/fourth-step.md";
import Markdown from "markdown-to-jsx";

export function TheFourthStep() {

    const [fourthStep, setForthStep] = useState('')

    useEffect(() => {
        fetch(FourthStepContents)
            .then((res) => res.text())
            .then(value => setForthStep(value))
            .catch(reason => console.log(reason))
    })

    return <><Markdown options={{
        overrides: {
            h1: {
                props: {
                    className: "has-text-centered title"
                }
            }
        }
    }}>{fourthStep}</Markdown>
    </>
}