import Contents from "../../markdown/links/useful-links.md"

import Markdown from "markdown-to-jsx";
import {useEffect, useState} from "react";

export function UsefulLinks() {

    const [contents, setContents] = useState('')

    useEffect(() => {
        fetch(Contents)
            .then((res) => res.text())
            .then(value => setContents(value))
            .catch(reason => console.log(reason))
    })
    return <>
        <section className="section" id="ancient">
            <div className="container">
                <div className="content has-text-left">
                    <Markdown options={{
                        overrides: {
                            h2: {
                                props: {
                                    className: "has-text-centered title"
                                }
                            }
                        }
                    }}>{contents}</Markdown>
                </div>
            </div>
        </section>
    </>
}

export function MarkBenevolentFund() {

    const [contents, setContents] = useState('')

    useEffect(() => {
        fetch(Contents)
            .then((res) => res.text())
            .then(value => setContents(value))
            .catch(reason => console.log(reason))
    })
    return <>
        <section className="section" id="ancient">
            <div className="container">
                <div className="content has-text-left">
                    <Markdown options={{
                        overrides: {
                            h2: {
                                props: {
                                    className: "has-text-centered title"
                                }
                            }
                        }
                    }}>{contents}</Markdown>
                </div>
            </div>
        </section>
    </>
}