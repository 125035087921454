import {Link} from "react-router-dom";

var rowcol = false;

function getRowStyle() {

    let style = rowcol ? "section" : "section has-background-grey-light";
    rowcol = !rowcol;
    return style;
}

export function LodgeAndMeetingLocation({data: lodge}) {
    return <div className={getRowStyle()} key={lodge.name}>
        <div className="columns">

            <div className="column">
                <p>{lodge.location}</p>
            </div>

            <div className="column">
                <Link to="/lodge" state={lodge}>{lodge.name}</Link>
            </div>

            <div className="column">
                <p>{lodge.meetingdetails}</p>
            </div>
        </div>
    </div>;
}