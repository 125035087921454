import {Helmet} from "react-helmet";

import logo from '../img/logos/logo.jpg'
import accredited from '../img/logos/glmmm-accredited-200.jpg'
import ram from '../img/logos/thumb_ram200_1024.jpg'

import '../main.sass'
import {useEffect, useState} from "react";

import footerFile from '../markdown/footer.md'
import headerFile from '../markdown/header.md'
import Markdown from "markdown-to-jsx";
import {Navbar} from "./navbar";


function Layout({children}) {

    const [header, setHeader] = useState('')
    const [footer, setFooter] = useState('')

    useEffect(() => {
        fetch(footerFile)
            .then((res) => res.text())
            .then(value => setFooter(value))
            .catch(reason => console.log(reason))

        fetch(headerFile)
            .then((res) => res.text())
            .then(value => setHeader(value))
            .catch(reason => console.log(reason))
    })

    return (
        <section className="section">
            <div className="container">
                <Helmet title="The Provincial Grand Lodge of Mark Master Masons of Leicestershire and Rutland "/>

                <div className="columns is-mobile is-full ">
                    <div className="column">
                        <img src={logo} alt="logo"/>
                    </div>

                    <div className="column has-text-centered is-10">
                        <h1 className="title"><Markdown>{header}</Markdown></h1>
                    </div>

                    <div className="column">
                        <img src={logo} alt="logo"/>
                    </div>
                </div>

                <Navbar/>
            </div>

            <div className="container">{children}</div>

            <footer className="footer container">
                <div className="columns is-centered is-vcentered">
                    <div className="column">
                        <img src={accredited} alt="accredited logo"/>
                    </div>
                    <div className="column is-10 has-text-centered">
                        <Markdown>{footer}</Markdown>
                    </div>
                    <div className="column">
                        <img src={ram} alt="ram logo"/>
                    </div>
                </div>
            </footer>


        </section>
    )
}

export default Layout