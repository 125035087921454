import MagicContents from "../../markdown/magic/magic-of-the-mark.md"
import Markdown from "markdown-to-jsx";
import {useEffect, useState} from "react";

export function MagicOfTheMark() {

    const [magic, setMagic] = useState('')

    useEffect(() => {
        fetch(MagicContents)
            .then((res) => res.text())
            .then(value => setMagic(value))
            .catch(reason => console.log(reason))
    })
    return <>
        <section className="section" id="ancient">
            <div className="container">
                <div className="content has-text-centered">
                    <Markdown options={{
                        overrides: {
                            h1: {
                                props: {
                                    className: "has-text-centered title"
                                }
                            }
                        }
                    }}>{magic}</Markdown>
                </div>
            </div>
        </section>
    </>
}