import {useEffect, useState} from "react";
import OurRamContents from "../../markdown/ram/our-ram-lodges.md";
import Markdown from "markdown-to-jsx";
import MeetingsPage from "../meetings";

export function OurRamLodges() {
    const [ourRamLodges, setOurRamLodges] = useState('')

    useEffect(() => {
        fetch(OurRamContents)
            .then((res) => res.text())
            .then(value => setOurRamLodges(value))
            .catch(reason => console.log(reason))
    })

    return <><Markdown options={{
        overrides: {
            h1: {
                props: {
                    className: "has-text-centered title"
                }
            }
        }
    }}>{ourRamLodges}</Markdown>
        <MeetingsPage type="RAM"/>
    </>
}