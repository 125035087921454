
import React from "react";
import {HTMLContent} from "../../components/content";

export function LocationComponent(props) {
    return <>
        <section>
            <div className="content has-text-centered">
                <h2>{props.location.address}</h2>
            </div>

            <div className="content has-text-centered">
                <p>Street View</p>
                <HTMLContent content={props.location.streetmap}/>
                <p>Map View:</p>
                <HTMLContent content={props.location.map} className=""/>
            </div>
        </section>
    </>
}