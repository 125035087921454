import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

import {Route, Routes} from 'react-router'
import {ProvincePage} from "./pages/province/province";
import HomePage from "./pages";
import ProvincialTeam from "./pages/province/provincialTeam";
import MeetingsPage from "./pages/meetings";
import {LodgeDetails} from "./pages/lodges/lodgeDetails";
import {TheMarkDegree} from "./pages/mark/mark";
import {JoinMarkMasons} from "./pages/mark/joinMarkMasons";
import {OurMarkLodges} from "./pages/mark/ourMarkLodges";
import {TheFourthStep} from "./pages/mark/theFourthStep";
import {
    RoyalArkMarinerDegree
} from "./pages/ram/ram";
import {TheAncientAndHonourableFraternity} from "./pages/ram/theAncientAndHonourableFraternity";
import {JoinRoyalArkMariner} from "./pages/ram/joinRoyalArkMariner";
import {OurRamLodges} from "./pages/ram/ourRamLodges";
import {MagicOfTheMark} from "./pages/magic/magic";
import {Honorifics, MarkBenevolentFund} from "./pages/mbf/mbf";
import {Charity} from "./pages/mbf/charity";
import Layout from "./components/layout";
import {UsefulLinks} from "./pages/links/links";
import {NotFoundPage} from "./pages/404";

ReactDOM.render(
    <BrowserRouter>
        <Layout>
            <Routes>
                <Route path="*" element={<NotFoundPage/>}/>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/province" element={<ProvincePage/>}/>
                <Route path="/provincial-team" element={<ProvincialTeam/>}/>
                <Route path="/meetings" element={<MeetingsPage/>}/>
                <Route path="/lodge" element={<LodgeDetails/>}/>
                <Route path="/mark" element={<TheMarkDegree/>}/>
                <Route path="/mark/fourth-step" element={<TheFourthStep/>}/>
                <Route path="/mark/join" element={<JoinMarkMasons/>}/>
                <Route path="/mark/lodges" element={<OurMarkLodges/>}/>
                <Route path="/ram" element={<RoyalArkMarinerDegree/>}/>
                <Route path="/ram/ancient" element={<TheAncientAndHonourableFraternity/>}/>
                <Route path="/ram/join" element={<JoinRoyalArkMariner/>}/>
                <Route path="/ram/lodges" element={<OurRamLodges/>}/>
                <Route path="/magic" element={<MagicOfTheMark/>}/>
                <Route path="/charity" element={<Charity/>}/>
                <Route path="/charity/mbf" element={<MarkBenevolentFund/>}/>
                <Route path="/honorifics" element={<Honorifics/>}/>
                <Route path="/links" element={<UsefulLinks/>}/>
            </Routes>
        </Layout>
    </BrowserRouter>,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
