import {useEffect, useState} from "react";
import Becoming from "../../markdown/mark/becoming-a-mark-master-mason.md";
import Markdown from "markdown-to-jsx";

export function JoinMarkMasons() {
    const [becoming, setBecoming] = useState('')

    useEffect(() => {
        fetch(Becoming)
            .then((res) => res.text())
            .then(value => setBecoming(value))
            .catch(reason => console.log(reason))
    })

    return <>
        <Markdown options={{
            overrides: {
                h1: {
                    props: {
                        className: "has-text-centered title"
                    }
                }
            }
        }}>{becoming}</Markdown>
    </>
}