import secretaryApron from '../img/home/thumb_Provincial Grand Secretary Apron_1024.jpeg'
import keyStone from '../img/home/thumb_Keystone Jewel_1024.jpeg'
import markMastersApron from '../img/home/thumb_Masters Mark Apron_1024.jpeg'
import pgm from '../img/home/R. W. Bro. Phillip J. Mann PUBLISHED.jpeg'
import Markdown from "markdown-to-jsx";

import welcomeFile from '../markdown/home/welcome.md'
import {useEffect, useState} from "react";


function HomePage() {

    const [welcome, setWelcome] = useState('')

    useEffect(() => {
        fetch(welcomeFile)
            .then((res) => res.text())
            .then(value => setWelcome(value))
            .catch(reason => console.log(reason))
    })

    return (
        <>
            <section className="section">
                <div className="columns">
                    <div className="column is-one-third">
                        <img src={secretaryApron} alt="Secretary Apron"/>
                    </div>
                    <div className="column is-one-third">
                        <img src={keyStone} alt="Keystone Jewel"/>
                    </div>
                    <div className="column is-one-third">
                        <img src={markMastersApron} alt="Mark Masters Apron"/>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="columns">
                    <div className="column has-text-centered">
                        <h2 className="title">Welcome from the Provincial Grand Master</h2>
                        <div className="columns  has-text-left">
                            <div className="column is-one-quarter">
                                <img src={pgm} alt="Provincial Grand Master"/>
                            </div>
                            <div className="column is-three-quarters">
                                <Markdown>{welcome}</Markdown>
                            </div>
                        </div>

                        {/*<div className={"has-text-left"}>*/}
                        {/*    <br/>*/}
                        {/*    <p>Sincerely and fraternally</p>*/}

                        {/*    <br/>*/}
                        {/*    <p className={"has-text-weight-bold"}>Phillip J. Mann</p>*/}
                        {/*    <p>Provincial Grand Master</p>*/}
                        {/*</div>*/}
                    </div>
                </div>


            </section>
        </>
    )
}

export default HomePage

