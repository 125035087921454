import {useEffect, useState} from "react";
import RamContents from "../../markdown/ram/the-ancient-and-honourable-fraternity.md";
import Markdown from "markdown-to-jsx";

export function TheAncientAndHonourableFraternity() {

    const [ram, setRam] = useState('')

    useEffect(() => {
        fetch(RamContents)
            .then((res) => res.text())
            .then(value => setRam(value))
            .catch(reason => console.log(reason))
    })

    return <><Markdown options={{
        overrides: {
            h1: {
                props: {
                    className: "has-text-centered title"
                }
            }
        }
    }}>{ram}</Markdown>
    </>
}