import {TheAncientAndHonourableFraternity} from "./theAncientAndHonourableFraternity";
import {JoinRoyalArkMariner} from "./joinRoyalArkMariner";
import {OurRamLodges} from "./ourRamLodges";

export function RoyalArkMarinerDegree() {

    return <>
        {<TheAncientAndHonourableFraternity/>}
        <br/>
        {<JoinRoyalArkMariner/>}
        <br/>
        {<OurRamLodges/>}
    </>
}