import {useEffect, useState} from "react";

import aboutContents from '../../markdown/about/about.md'

import Markdown from "markdown-to-jsx";
import {ProvincialTeam} from "./provincialTeam";


export function ProvincePage() {

    const [about, setAbout] = useState('')


    useEffect(() => {
        fetch(aboutContents)
            .then((res) => res.text())
            .then(value => setAbout(value))
            .catch(reason => console.log(reason))
    })

    return (
        <>
            <section className="section section--gradient" id="history">
                <div className="container">
                    <div className="section has-text-centered">
                        <h1 className="title">About</h1>
                        <Markdown>{about}</Markdown>
                    </div>
                </div>
            </section>

            <section className="section section--gradient" id="executive">
                {<ProvincialTeam/>}
            </section>
        </>
    )
}

