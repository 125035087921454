let rowcol = false

export function getRowStyle() {

    let style = rowcol ? "columns is-multiline has-background-grey-light" : "columns is-multiline";
    rowcol = !rowcol;
    return style;
}





