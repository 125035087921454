import Markdown from "markdown-to-jsx";
import pgm from "../../img/officers/provincial-grand-master.png";
import dpgm from "../../img/officers/deputy-provincial-grand-master.png";
import secretary from "../../img/officers/provincial-grand-secretary.png";
import provincialTeamContents from "../../markdown/about/team.md";
import {useEffect, useState} from "react";
import GrandOfficers from "./grandOfficers";

export function ProvincialTeam() {
    const [team, setTeam] = useState('')

    useEffect(() => {
        fetch(provincialTeamContents)
            .then((res) => res.text())
            .then(value => setTeam(value))
            .catch(reason => console.log(reason))
    })

    return (
        <>
            <div className="section has-text-centered">
                <h1 className="title">Provincial Team</h1>
                <div className="section has-text-centered">
                    <Markdown>{team}</Markdown>
                </div>

                <div className="columns">
                    <div className="column is-4"/>
                    <div className="column is-4">

                        <div className="has-text-centered">
                            <p>Provincial Grand Master</p>
                            <img alt="" src={pgm}/>
                        </div>
                        <p>Right Worshipful Brother Phillip J. Mann </p>
                        <p>Installed May 2017</p>
                    </div>
                    <div className="column is-4"/>
                </div>

                <div className="columns">
                    <div className="column is-2"/>
                    <div className="column is-4">
                        <div className="has-text-centered">
                            <p>Deputy Provincial Grand Master</p>
                            <img alt="" src={dpgm} />
                        </div>
                        <p>Worshipful Brother Andrew S. Lee, P.A.G.D.C. </p>
                        <p>Appointed May 2020</p>
                    </div>
                    <div className="column is-4 ">
                        <div className="has-text-centered">
                            <p>Provincial Grand Secretary</p>
                            <img alt=""
                                 src={secretary}/>
                        </div>
                        <p>Worshipful Brother Roger A.E. Whale</p>
                        <p>Appointed and Invested May 2023</p>
                    </div>
                    <div className="column is-2"/>
                </div>
            </div>

            <section className="section" id="grandofficers">
                {<GrandOfficers/>}
            </section>
        </>)
}

export default ProvincialTeam