import {Link} from "react-router-dom";
import React from "react";

export class Navbar extends React.Component {
    state = {
        isActive: false
    };

    toggleNav = () => {
        this.setState(prevState => ({
            isActive: !prevState.isActive
        }));
    };

    render() {
        return (
            <nav className="navbar  ">
                <div className="navbar-brand">
                    <div
                        className="navbar-burger burger"
                        onClick={this.toggleNav}
                        data-target="mmmNavbar"
                    >
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>

                <div id="mmmNavbar" className={this.getClassName()}>
                    <div className="navbar-start">
                        <Link className="navbar-item" to="/" onClick={(event) => this.menuClicked(event)}>
                            HOME
                        </Link>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/province" onClick={(event) => this.menuClicked(event)}>
                                ABOUT
                            </Link>
                            <div className="navbar-dropdown is-boxed">
                                <Link className="navbar-item" to="/province#history"
                                      onClick={(event) => this.menuClicked(event)}>
                                    Our Province
                                </Link>
                                <Link className="navbar-item" to="/provincial-team"
                                      onClick={(event) => this.menuClicked(event)}>
                                    The Provincial Team
                                </Link>
                                <Link className="navbar-item" to="/meetings" onClick={(event) => this.menuClicked(event)}>
                                    Lodge Meetings
                                </Link>
                                <Link className="navbar-item" to="/news" onClick={(event) => this.menuClicked(event)}>
                                    News
                                </Link>
                            </div>
                        </div>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/mark" onClick={(event) => this.menuClicked(event)}>
                                THE MARK DEGREE
                            </Link>
                            <div className="navbar-dropdown is-boxed">
                                <Link className="navbar-item" to="/mark/fourth-step"
                                      onClick={(event) => this.menuClicked(event)}>
                                    The Fourth Step
                                </Link>
                                <Link className="navbar-item" to="/mark/lodges"
                                      onClick={(event) => this.menuClicked(event)}>
                                    Our Mark Lodges
                                </Link>
                                <Link className="navbar-item" to="/mark/join" onClick={(event) => this.menuClicked(event)}>
                                    Becoming a Mark Master Mason
                                </Link>
                            </div>
                        </div>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/ram" onClick={(event) => this.menuClicked(event)}>
                                ROYAL ARK MARINER
                            </Link>
                            <div className="navbar-dropdown is-boxed">
                                <Link className="navbar-item" to="/ram/ancient"
                                      onClick={(event) => this.menuClicked(event)}>
                                    The Ancient & Honourable Fraternity
                                </Link>
                                <Link className="navbar-item" to="/ram/lodges" onClick={(event) => this.menuClicked(event)}>
                                    Our Royal Ark Mariner Lodges
                                </Link>
                                <Link className="navbar-item" to="/ram/join" onClick={(event) => this.menuClicked(event)}>
                                    Becoming a Royal Ark Mariner
                                </Link>
                            </div>
                        </div>

                        <div className="navbar-item is-hoverable">
                            <Link className="navbar-item" to="/magic" onClick={(event) => this.menuClicked(event)}>
                                MAGIC OF THE MARK
                            </Link>
                        </div>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/charity" onClick={(event) => this.menuClicked(event)}>
                                MBF
                            </Link>
                            <div className="navbar-dropdown is-boxed">
                                <Link className="navbar-item" to="/charity/mbf"
                                      onClick={(event) => this.menuClicked(event)}>
                                    Mark Benevolent Fund
                                </Link>
                                <Link className="navbar-item" to="/honorifics" onClick={(event) => this.menuClicked(event)}>
                                    Honorifics
                                </Link>
                            </div>
                        </div>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/" onClick={(event) => this.menuClicked(event)}>
                                FORMS
                            </Link>
                            <div className="navbar-dropdown is-boxed">
                                <a href="https://markmasonshall.org/mmm-downloads" className="navbar-item"
                                   target="_blank" rel="noreferrer">Mark Master Mason</a>

                                <a href="https://markmasonshall.org/downloads" className="navbar-item" target="_blank"
                                   rel="noreferrer">Royal Ark Mariner</a>
                            </div>
                        </div>

                        <div className="navbar-item is-hoverable">
                            <Link className="navbar-item" to="/links" onClick={(event) => this.menuClicked(event)}>
                                LINKS
                            </Link>
                        </div>

                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/" onClick={(event) => this.menuClicked(event)}>
                                CONTACT US
                            </Link>
                            <div className="navbar-dropdown is-boxed">
                                <a className="navbar-item"
                                   href="mailto:provsec@leicsrutlandmarkmasons.org?subject=Enquiry From MMM Website">
                                    Provincial Grand Secretary
                                </a>

                                <a className="navbar-item"
                                   href="mailto:registrar@leicsrutlandmarkmasons.org?subject=Enquiry From MMM Website">
                                    Magic of the Mark Editor
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }

    menuClicked(event) {
        event.target.blur();
        this.setState(() => ({
            isActive: false
        }));
    }

    getClassName() {
        return this.state.isActive ? "navbar-menu is-active" : "navbar-menu";
    }
}