import lodges from "../data/lodges.json";
import {LodgeAndMeetingLocation} from "./lodgeAndMeetingLocation";

function MeetingsPage({type = "ALL"}) {
    return (
        <>
            <section className="section">
                <div className="container has-text-centered">
                    <h1 className="title">Lodges and Meeting Locations</h1>
                    <p>Click on the lodges below to see meeting dates and maps</p>
                </div>
            </section>

            {type === "ALL" ?
                lodges.lodges.map((lodge, index) => <div key={index}><LodgeAndMeetingLocation data={lodge}/></div>)
                :
                lodges.lodges.filter(x => x.type === type).map((lodge, index) => <div key={index}><LodgeAndMeetingLocation data={lodge}/></div>)
            }

        </>
    )
}

export default MeetingsPage