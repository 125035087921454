import {JoinMarkMasons} from "./joinMarkMasons";
import {OurMarkLodges} from "./ourMarkLodges";
import {TheFourthStep} from "./theFourthStep";

export function TheMarkDegree() {

    return <>
        {<TheFourthStep/>}
        <br/>
        {<JoinMarkMasons/>}
        <br/>
        {<OurMarkLodges/>}
    </>
}