import {useEffect, useState} from "react";
import OurMarkLodgesContent from "../../markdown/mark/our-mark-lodges.md";
import Markdown from "markdown-to-jsx";
import MeetingsPage from "../meetings";

export function OurMarkLodges() {
    const [ourMarkLodges, setOurMarkLodges] = useState('')

    useEffect(() => {
        fetch(OurMarkLodgesContent)
            .then((res) => res.text())
            .then(value => setOurMarkLodges(value))
            .catch(reason => console.log(reason))
    })

    return <><Markdown options={{
        overrides: {
            h1: {
                props: {
                    className: "has-text-centered title"
                }
            }
        }
    }}>{ourMarkLodges}</Markdown>
        <MeetingsPage type="MARK"/>
    </>
}