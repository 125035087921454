import {useEffect, useState} from "react";
import JoinRamContents from "../../markdown/ram/join-royal-ark-mariner.md";
import Markdown from "markdown-to-jsx";

export function JoinRoyalArkMariner() {
    const [joinRam, setJoinRam] = useState('')

    useEffect(() => {
        fetch(JoinRamContents)
            .then((res) => res.text())
            .then(value => setJoinRam(value))
            .catch(reason => console.log(reason))
    })

    return <><Markdown options={{
        overrides: {
            h1: {
                props: {
                    className: "has-text-centered title"
                }
            }
        }
    }}>{joinRam}</Markdown>
    </>
}