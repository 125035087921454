import officers from "../../data/grand-officers.json";
import lodges from "../../data/lodges.json";
import {getRowStyle} from "./getRowStyle";



export function GrandOfficers() {
    return <div className="section has-text-centered">
        <h1 className="title">{officers.title}</h1>
        <div className="section">
            <div className="columns is-fixed-top has-background-black has-text-white">
                <div className="column">
                    <p className="heading">Office</p>
                </div>
                <div className="column">
                    <p className="heading">Name</p>
                </div>
                <div className="column">
                    <p className="heading">Lodge</p>
                </div>
                <div className="column">
                    <p className="heading">Number</p>
                </div>
            </div>


            {officers.ProvincialGrandOfficers.map((item, index) => (

                <div className={getRowStyle()} key={index}>
                    <div className="column">
                        <p className="column">{item.office}</p>
                    </div>
                    < div className="column">
                        <p>{item.name}</p>
                    </div>
                    <div className="column">
                        <p>{(lodges.lodges.find(x => x.type === "MARK" && x.number === item.lodge)).name}</p>
                    </div>
                    <div className="column">
                        <p>{item.lodge}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>;
}

export default GrandOfficers